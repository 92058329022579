@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100&display=swap");

/*Start Perfect Scroll*/
/*
 * Container style
 */

@media screen and (max-width: 480px) {
    .w-screen {
        min-width: 100%;
    }
    #veriff-root {
        min-width: 200px;
        /* background-color: red; */
    }
    .Veriff {
        min-width: 480px;
    }
}
@media screen and (min-width: 924px) {
    #veriff-root {
        min-width: 200%;
        width: 9000px;
        /* margin-left: 50px; */
    }
    #root_verr {
        min-width: 100%;
        max-width: 900px;
        object-fit: cover;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
}
.veriff-submit {
    display: none;
}
.App {
    text-align: center;
    margin-top: 20px;
}

.center {
    display: flex;
    justify-content: center;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

span {
    font-family: "Inter", sans-serif;
    font-weight: 400;
}

label {
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

p {
    font-family: "Inter", sans-serif;
    font-weight: 300;
    color: #c0c0c0;
}

/*
 * Scrollbar rail styles
 */
.ps__rail-x {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    height: 10px;
    /* there must be 'bottom' or 'top' for ps__rail-x */
    bottom: 0px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-y {
    display: none;
    opacity: 0;
    transition: background-color 0.2s linear, opacity 0.2s linear;
    -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
    width: 10px;
    /* there must be 'right' or 'left' for ps__rail-y */
    right: 0;
    /* please don't change 'position' */
    position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: #f1f2f3;
    opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
    background-color: #d3d3d3;
    border-radius: 6px;
    transition: background-color 0.2s linear, height 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
    height: 4px;
    /* there must be 'bottom' for ps__thumb-x */
    bottom: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__thumb-y {
    background-color: #d3d3d3;
    border-radius: 6px;
    transition: background-color 0.2s linear, width 0.2s ease-in-out;
    -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
    width: 4px;
    /* there must be 'right' for ps__thumb-y */
    right: 2px;
    /* please don't change 'position' */
    position: absolute;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
    background-color: #888ea8;
    height: 6px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
    background-color: #888ea8;
    width: 6px;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}
/*End Perfect Scroll*/
.tab-item-animate {
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(100% - 12px);
    height: 32px;
    transform-origin: 0 0;
    transition: transform 0.25s;
}

.tabs .tabs-item:first-child.active ~ .tab-item-animate {
    transform: translateX(0) scaleX(0.5);
}

.tabs .tabs-item:nth-child(2).active ~ .tab-item-animate {
    transform: translateX(50%) scaleX(0.5);
}
